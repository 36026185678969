import { render, staticRenderFns } from "./recommend.vue?vue&type=template&id=19d36ca4&scoped=true&"
import script from "./recommend.vue?vue&type=script&lang=js&"
export * from "./recommend.vue?vue&type=script&lang=js&"
import style0 from "./recommend.vue?vue&type=style&index=0&id=19d36ca4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d36ca4",
  null
  
)

export default component.exports