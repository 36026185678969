<template>
  <!--排行榜-->
  <div class="topMain">
    <div class="topList">
      <music-loading v-model="isShowLoad" />
      <template v-if="!isShowLoad">
        <div class="topList-head">推荐榜单</div>
        <div class="topList-content">
          <div
            v-for="(item, index) in rankList"
            :key="index"
            class="list-item"
            :title="`${item.name}-${item.updateFrequency}`"
          >
            <router-link
              :to="{ path: `/music/details/${item.id}` }"
              tag="div"
              class="topList-item"
            >
              <div class="topList-img">
                <img
                  v-lazy="`${item.coverImgUrl}?param=300y300`"
                  class="cover-img"
                />
              </div>
              <h3 class="name">{{ item.name }}</h3>
            </router-link>
          </div>
        </div>
        <div class="topList-head">推荐歌曲</div>
        <div class="topList-content">
          <div
            v-for="(item, index) in songList"
            :key="index"
            class="list-item"
            :title="item.name"
          >
            <div class="topList-item" @click.stop="selectItem(item)">
              <div class="topList-img">
                <img v-lazy="`${item.picUrl}?param=300y300`" class="cover-img" />
              </div>
              <h3 class="name">{{ item.name }}</h3>
            </div>
          </div>
        </div>
        <div class="topList-head">推荐歌单</div>
        <div class="topList-content">
          <div
            v-for="(item, index) in sheetList"
            :key="index"
            class="list-item"
            :title="item.name"
          >
            <router-link
              :to="{ path: `/music/details/${item.id}` }"
              tag="div"
              class="topList-item"
            >
              <div class="topList-img">
                <img v-lazy="`${item.picUrl}?param=300y300`" class="cover-img" />
              </div>
              <h3 class="name">{{ item.name }}</h3>
            </router-link>
          </div>
        </div>
        <div class="topList-head topList-mv">推荐MV</div>
        <div class="topList-content topList-mv">
          <div
            v-for="(item, index) in mvList"
            :key="index"
            class="list-mv-item"
            :title="`${item.name} - ${item.artistName}`"
          >
            <div class="topList-mv-item" @click.stop="playItem(item)">
              <div class="topList-mv-img">
                <img v-lazy="`${item.cover}?param=300y160`" class="cover-mv-img" />
                <i class="mod_cover__mask"></i>
                <music-icon class="mod_cover__icon_play" type="bofangMV" :size="80"/>
              </div>
              <h3 class="name">{{ item.name }}</h3>
              <div class="info-box">
                <span class="siger">{{ item.artistName }}</span>
                <span class="time">{{ item.duration / 1000 | format }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <music-loading v-model="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getRecommendRank, getRecommendSong, getRecommendSheet, getRecommendMV, getMusicDetail, getMovieUrl } from 'api'
import { loadMixin } from '@/utils/mixin'
import { toHttps, format } from '@/utils/util'
import MusicLoading from 'components/music-common/loading/music-loading'

export default {
  name: 'PlayList',
  components: {
    MusicLoading
  },
  mixins: [loadMixin],
  data() {
    return {
      rankList: [], // 推荐榜单
      songList: [], // 推荐歌曲
      sheetList: [], // 推荐歌单
      mvList: [], // 推荐MV
      isLoading: false
    }
  },
  filters: {
    format
  },
  computed: {
    ...mapGetters(['playing', 'currentMusic'])
  },
  created() {
    Promise.all([getRecommendRank(), getRecommendSong(), getRecommendSheet(), getRecommendMV()])
      .then(([rankList, songList, sheetList, mvList]) => {
        this.rankList = rankList.list.filter(v => v.ToplistType)
        this.songList = songList.result.slice()
        this.sheetList = sheetList.result.slice()
        this.mvList = mvList.data.slice()
        this._hideLoad()
      })
      .catch(() => {})
  },
  methods:{
    // 播放歌曲
    async selectItem(music) {
      try {
        const image = await this._getMusicDetail(music.id)
        music.image = toHttps(image)
        this.selectAddPlay(music)
      } catch (error) {
        this.$Toast('歌曲播放失败')
      }
    },
    // 歌曲详情
    _getMusicDetail(id) {
      return getMusicDetail(id).then((res) => res.songs[0].al.picUrl)
    },
    // 播放视频
    playItem(item) {
      this.isLoading = true;

      getMovieUrl(item.id).then(res => {
        this.isLoading = false;

        if(this.playing){
          this.setPlaying(!this.playing);
        }

        let payload = {
          "url": res.data.url,
          "flag": true
        }
        this.setVideoState(payload)
      }).catch(err => {
        console.log(err);
        this.isLoading = false;
      })
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING',
      setVideoState: 'SET_VIDEOSTATE'
    }),
    ...mapActions(['selectAddPlay'])
  }
}
</script>

<style lang="less" scoped>
.topMain{
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.8s;
}
.topList {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &-head {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 20px 0;
    font-size: @font_size_large;
    color: @text_color_active;
  }
  &-content {
    overflow: hidden;
  }
  .list-item {
    float: left;
    width: calc(~'100% / 7');
    .topList-item {
      width: 130px;
      text-align: center;
      cursor: url('https://qiniu-music.prochick.top/cursor/S2.cur'), auto !important;
      margin: 0 auto 20px;
      &:hover {
        color: #fff;
      }
      .name {
        height: 30px;
        line-height: 30px;
        font-size: @font_size_medium;
        .no-wrap();
      }
      @media (max-width: 1100px) {
        width: 80%;
      }
    }
    @media (max-width: 1500px) {
      width: calc(~'100% / 6');
    }
    @media (max-width: 1400px), (max-width: 960px) {
      width: calc(~'100% / 5');
    }
    @media (max-width: 1280px), (max-width: 768px) {
      width: calc(~'100% / 4');
    }
    @media (max-width: 540px) {
      width: calc(~'100% / 3');
    }

    .topList-img {
      position: relative;
      padding-top: 100%;
      width: 100%;
      height: 0;
      .cover-img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 18%;
        transition: all 0.7s ease-in-out;
        transform: scale(0.95);
      }
      .cover-img:hover{
        transform: scale(1.0);
      }
    }
  }

  .topList-mv .list-mv-item{
    float: left;
    width: calc(~'100% / 4');
    .topList-mv-item {
      width: 250px;
      text-align: center;
      cursor: url('https://qiniu-music.prochick.top/cursor/S2.cur'), auto !important;
      margin: 0 auto 20px;
      &:hover .name{
        color: #fff;
      }
      .name {
        height: 30px;
        line-height: 40px;
        font-size: @font_size_medium;
        .no-wrap();
      }
      .info-box{
        display: flex;
        margin: 10px 0;
        color: lightblue;
        opacity: .7;
      }
      .siger{
        display: block;
        width: 70%;
        text-align: left;
        .no-wrap();
      }
      .time{
        display: block;
        width: 30%;
        text-align: right;
      }
      @media (max-width: 1100px) {
        width: 80%;
      }
    }
    @media (max-width: 1400px) {
      width: calc(~'100% / 3');
    }

    .topList-mv-img {
      position: relative;
      padding-top: 64%;
      width: 100%;
      height: 0;
      overflow: hidden;
      border-radius: 3%;
      .mod_cover__mask{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: opacity .5s;
      }
      .mod_cover__icon_play{
        position: absolute;
        left: 35%;
        top: 30%;
        opacity: 0;
        transform: scale(.7) translateZ(0);
        transition-property: opacity,transform;
        transition-duration: .5s;
        zoom: 1;
      }
      .cover-mv-img {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.7s ease-in-out;
      }
    }
    .topList-mv-img:hover .mod_cover__mask{
      opacity: .4;
    }
    .topList-mv-img:hover .mod_cover__icon_play{
      opacity: 1;
      transform: scale(.9) translateZ(0);
    }
    .topList-mv-img:hover .cover-mv-img{
      transform: scale(1.2);
    }
  }
}
@media (max-width: 640px) {
  .topList-mv{
    display: none;
  }
}
::-webkit-scrollbar-thumb {
  visibility: hidden;
  background: #757F9A;
  background: -webkit-linear-gradient(to right, #D7DDE8, #757F9A);
  background: linear-gradient(to right, #D7DDE8, #757F9A);
  border-radius: 10px;
  transition: all 0.8s;
}
.topMain:hover ::-webkit-scrollbar-thumb {
  visibility: visible;
}
</style>
